import { AnimatePresence } from "framer-motion";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import ProductBanner from "../components/ProductBanner";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import ProductProjects from "../components/ProductProjects";
import NavMain from "../components/NavMain";
import Footer from "../components/Footer";

const banner = [
  {
    product: "Spatial Asset Management",
    line1:
      "Gain a realtime business insight from your asset data combined with location perspective.",
    line2: null,
    banner: `${require("../images/sam.jpg").default}`,
  },
];

const description =
  "An enterprise system that processes spatial & non-spatial data into information used to collect, store, manipulate, analyze, and display data based on digital network maps.";

const features = [
  {
    img: `${require("../images/screenshot/B1.png").default}`,
    title: "Asset optimalization with location attribute data",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/B2.png").default}`,
    title:
      "Data integrity that can be used with any other division for decision making process",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/B3.png").default}`,
    title:
      "Easiness of creating applications for registering, maintaining, visualizing and analyzing asset data",
    desc: "Easiness of creating applications for registering, maintaining, visualizing and analyzing asset data",
  },
];

const benefits = [
  {
    img: `${require("../images/solution/SAM 1.png").default}`,
    title: "Manage Asset Data at Your Fingertips",
    text: "You can move, delete, and add assets wherever you maybe with our system-agnostic application",
  },
  {
    img: `${require("../images/solution/SAM 2.png").default}`,
    title: "Execute Plan Precisely and Work Effectively",
    text: "Making decision is easier if you have your data visualized",
  },
  {
    img: `${require("../images/solution/SAM 3.png").default}`,
    title: "Reduce cost and optimize revenue",
    text: "Easiness of creating applications for registering, maintaining, visualizing and analyzing asset data",
  },
];

const projects = [
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "blue",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
  {
    link: "/projects/datacapture",
    name: "Data Capture",
    type: "Mobile App",
    color: "purple",
    logo: `${require("../images/logo/logo-05.png").default}`,
    img: `${require("../images/datacapture.png").default}`,
  },
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "red",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
];

function SAM() {
  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <NavMain />
          <ProductBanner data={banner} />
          <ProductFeatures data={features} desc={description} />
          <ProductBenefits data={benefits} />
          <ProductProjects data={projects} />
          <div className="news solution">
            <img src={require("../images/question.png").default} alt="" />
            <h2>Got any question?</h2>
            <Link to="contactus">Send us a message</Link>
          </div>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default SAM;
