import React from "react";

function TestimonialItem(props) {
  return (
    <>
      <div className="testimonial__wrapper">
        <div className="testimonial__photo">
          <img src={props.img} alt="" />
        </div>
        <div className="testimonial__text">
          <p>{props.quote}</p>
          <div className="testimonial__text__detail">
            <h3>{props.name}</h3>
            <h5>{props.company}</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialItem;
