import React from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation, Autoplay]);

function ProductFeatures(props) {
  return (
    <>
      <div className="product__about" id="features">
        <div data-aos="fade-up" className="content__title">
          <h2>{props.desc}</h2>
          <p>Here are some of the features</p>
        </div>
        <Swiper
          data-aos="fade-up"
          slidesPerView={"auto"}
          spaceBetween={30}
          navigation
          centeredSlides={true}
          autoplay={{ delay: 3000 }}
        >
          <SwiperSlide className="product__swiper__slide">
            <img src={props.data[0].img} alt="Feature 1" />
            <p className="product__swiper__slide__text">
              {props.data[0].title}
            </p>
          </SwiperSlide>
          <SwiperSlide className="product__swiper__slide">
            <img src={props.data[1].img} alt="Feature 2" />
            <p className="product__swiper__slide__text">
              {props.data[1].title}
            </p>
          </SwiperSlide>
          <SwiperSlide className="product__swiper__slide">
            <img src={props.data[2].img} alt="Feature 3" />
            <p className="product__swiper__slide__text">
              {props.data[2].title}
            </p>
          </SwiperSlide>
        </Swiper>
        <div data-aos="fade-up" className="product__swiper__text">
          {/* <h2>{props.data[0].title}</h2>
          <p>{props.data[0].desc}</p> */}
        </div>
      </div>
    </>
  );
}

export default ProductFeatures;
