import React from "react";
import CardBenefits from "./CardBenefits";

function ProductBenefits(props) {
  return (
    <>
      <div className="product__benefits" id="benefits">
        <div data-aos="fade-up" className="content__title">
          <h1>benefits</h1>
        </div>
        <div className="benefits__card__container">
          <CardBenefits
            img={props.data[0].img}
            title={props.data[0].title}
            text={props.data[0].text}
          />
          <CardBenefits
            img={props.data[1].img}
            title={props.data[1].title}
            text={props.data[1].text}
          />
          <CardBenefits
            img={props.data[2].img}
            title={props.data[2].title}
            text={props.data[2].text}
          />
        </div>
      </div>
    </>
  );
}

export default ProductBenefits;
