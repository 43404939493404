import React, { useState } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as LeftArrow } from "../images/icon/arrow.svg";
import { ReactComponent as RightArrow } from "../images/icon/chevron.svg";
import { ReactComponent as ContactUs } from "../images/icon/contact.svg";
import { ReactComponent as Login } from "../images/icon/login.svg";
import { ReactComponent as Projects } from "../images/icon/projects.svg";
import OutsideClickHandler from "react-outside-click-handler";
// import { Button } from "./Button";

const Navbar = (props) => {
  return (
    <div className="navbar" id="navbar">
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="Mitratech Indonesia" />
      </Link>
      <ul className="navbar-nav">{props.children}</ul>
    </div>
  );
};

function NavMenu(props) {
  const [open, setOpen] = useState(false);
  const isLink = props.to;

  return (
    <li className={`nav__item desktop ${props.login && "login"}`}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        {isLink ? (
          <Link
            to={props.to}
            className="icon-button"
            onClick={() => setOpen(!open)}
          >
            {props.icon}
          </Link>
        ) : (
          <div className="icon-button" onClick={() => setOpen(!open)}>
            {props.icon}
          </div>
        )}
        {open && props.children}
      </OutsideClickHandler>
    </li>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="nav__item">
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        {/* eslint-disable-next-line */}
        <div
          to={props.to}
          className="icon-button"
          onClick={() => setOpen(!open)}
        >
          <i className={open ? "fas fa-times fa-2x" : "fas fa-bars fa-2x"}></i>
        </div>
        {open && props.children}
      </OutsideClickHandler>
    </li>
  );
}

function DropdownDesktop() {
  function DropdownItem(props) {
    return (
      // eslint-disable-next-line
      <Link to={props.to} className="menu-item">
        <span className={`icon-left ${props.noLeft && "hidden"}`}>
          {props.leftIcon}
        </span>
        {props.children}
        <span className={`icon-right ${props.noRight && "hidden"}`}>
          {props.rightIcon}
        </span>
      </Link>
    );
  }

  return (
    <div className="dropdown desktop">
      <div className="menu">
        <DropdownItem to="/smartawe" noLeft noRight>
          Smart AWE
        </DropdownItem>
        <DropdownItem to="/spatialasset" noLeft noRight>
          Spatial Asset Management
        </DropdownItem>
        <DropdownItem to="/phdmanagement" noLeft noRight>
          PHD Management System
        </DropdownItem>
        <DropdownItem to="/iotintegration" noLeft noRight>
          IOT Integration
        </DropdownItem>
        <DropdownItem to="/dataacquisition" noLeft noRight>
          Data Acquisition
        </DropdownItem>
      </div>
    </div>
  );
}

function Dropdown() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height * 1.15);
  }

  function DropdownItem(props) {
    const isLink = props.to;
    return (
      <>
        {isLink ? (
          <Link
            to={props.to}
            className="menu-item"
            onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
          >
            <span className={`icon-left ${props.noLeft && "hidden"}`}>
              {props.leftIcon}
            </span>
            {props.children}
            <span className={`icon-right ${props.noRight && "hidden"}`}>
              {props.rightIcon}
            </span>
          </Link>
        ) : (
          <div
            to={props.to}
            className="menu-item"
            onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
          >
            <span className={`icon-left ${props.noLeft && "hidden"}`}>
              {props.leftIcon}
            </span>
            {props.children}
            <span className={`icon-right ${props.noRight && "hidden"}`}>
              {props.rightIcon}
            </span>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="projects" noLeft rightIcon={<RightArrow />}>
            Solutions
          </DropdownItem>
          <DropdownItem noRight to="/404" leftIcon={<Projects />}>
            Projects
          </DropdownItem>
          <DropdownItem noRight to="/newsandevents" leftIcon={<Projects />}>
            News and Events
          </DropdownItem>
          <DropdownItem noRight to="contactus" leftIcon={<ContactUs />}>
            Contact Us
          </DropdownItem>
          <a
            className="menu-item"
            href="https://services.mitratechindonesia.co.id/dashboard/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="icon-left">
              <Login />
            </span>
            Log In
          </a>
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === "projects"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem leftIcon={<LeftArrow />} goToMenu="main" noRight>
            Solutions
          </DropdownItem>
          <DropdownItem to="/smartawe" noRight noLeft>
            Smart AWE
          </DropdownItem>
          <DropdownItem to="/spatialasset" noRight noLeft>
            Spatial Asset Management
          </DropdownItem>
          <DropdownItem to="/phdmanagement" noRight noLeft>
            PHD Management System
          </DropdownItem>
          <DropdownItem to="/iotintegration" noRight noLeft>
            IOT Integration
          </DropdownItem>
          <DropdownItem to="/dataacquisition" noRight noLeft>
            Data Acquisition
          </DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

// Hide on scroll
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-150px";
  }
  prevScrollpos = currentScrollPos;
};

export { NavMenu, NavItem, Dropdown, DropdownDesktop };
export default Navbar;
