import React from "react";
import TextTransition, { presets } from "react-text-transition";
import { motion } from "framer-motion";
// import hero from "../images/isometric-01.png";

const TEXTS = ["IT Solutions", "IoT", "GIS", "Mobile App"];

function HeroSection() {
  // Looping text changer
  // Reference: //www.npmjs.com/package/react-text-transition
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="hero-container">
      <div className="hero-top">
        <motion.div
          initial={{ opacity: 0, x: "5vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="hero-img"
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: "-5vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="hero-text"
        >
          <h1>
            Your trusted <br />
            <TextTransition
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.slow}
              className="warna"
              inline
            />
            <br />
            partner
          </h1>
        </motion.div>
      </div>
      <div className="hero-bot">
        {/* <div className="hero-btn">Learn more about us</div> */}
        <div className="arrow-container">
          <div className="arrow-down">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* <h3>trusted by these companies</h3> */}
      </div>
    </div>
  );
}

export default HeroSection;
