import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import About from "../components/About";
import Cards from "../components/Cards";
import Client from "../components/Client";
import HeroSection from "../components/HeroSection";
import Products from "../components/Products";
import Testimonial from "../components/Testimonial";
import NavMain from "../components/NavMain";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      {/* <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
      >
        <NavMain />
        <HeroSection />
        <Client />
        <About />
        <Cards />
        <Testimonial />
        <Products />
        <div className="news">
          <h2 data-aos="fade-up">News and Events</h2>
          <Link data-aos="fade-up" to="/newsandevents">
            Stay up-to-date with our latest news &nbsp; &nbsp; →
          </Link>
        </div>
        <Footer />
      </motion.div>
      {/* </AnimatePresence> */}
    </>
  );
}

export default Home;
