import React from "react";
import Marquee from "react-fast-marquee";

const Logos = [
  {
    company: "PT. Perkebunan Nusantara IV",
    img: `${require("../images/logo/logo-01.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Sekretariat Negara",
    img: `${require("../images/logo/logo-02.png").default}`,
    orientation: "potrait",
  },
  {
    company: "PT. Pertagas Niaga",
    img: `${require("../images/logo/logo-03.png").default}`,
    orientation: "landscape",
  },
  {
    company: "Pemda Kabupaten Pringsewu",
    img: `${require("../images/logo/logo-04.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Badan Koordinasi Penanaman Modal",
    img: `${require("../images/logo/logo-05.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Dinas Perhubungan Kota Depok",
    img: `${require("../images/logo/logo-06.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Badan Nasional Pengelolaan Perbatasan",
    img: `${require("../images/logo/logo-07.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Pemerintah Provinsi Lampung",
    img: `${require("../images/logo/logo-08.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Dinas Cipta Karya, Tata Ruang & Pertanahan Pemprov DKI Jakarta",
    img: `${require("../images/logo/logo-09.png").default}`,
    orientation: "potrait",
  },
  {
    company: "PT. Inovasi Mandiri Pratama",
    img: `${require("../images/logo/logo-10.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. Mastersystem Infotama",
    img: `${require("../images/logo/logo-11.png").default}`,
    orientation: "landscape",
  },
  {
    company: "Dinas Perhubungan Kota Bandung",
    img: `${require("../images/logo/logo-12.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. Mahakam Gerbang Raja Migas",
    img: `${require("../images/logo/logo-13.png").default}`,
    orientation: "potrait",
  },
  {
    company: "PT. Nityo Infotech",
    img: `${require("../images/logo/logo-14.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. ECS Indojaya",
    img: `${require("../images/logo/logo-15.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. Telkom Satelit Indonesia",
    img: `${require("../images/logo/logo-16.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. Maligo Mas Utama",
    img: `${require("../images/logo/logo-17.png").default}`,
    orientation: "landscape",
  },
  {
    company: "Kementrian Kesehatan Republik Indonesia",
    img: `${require("../images/logo/logo-18.png").default}`,
    orientation: "landscape",
  },
  {
    company: "PT. Pemuka Sakti Manis Indah",
    img: `${require("../images/logo/logo-19.png").default}`,
    orientation: "landscape",
  },
  {
    company: "Kementrian Dalam Negeri",
    img: `${require("../images/logo/logo-20.png").default}`,
    orientation: "potrait",
  },
  {
    company: "Dinas Komunikasi dan Informatika Provinsi Banten",
    img: `${require("../images/logo/logo-21.png").default}`,
    orientation: "landscape",
  },
];

function Client() {
  function ClientItem(props) {
    return (
      <div className="client__item__container">
        <div className="client__item">
          <img className={props.orientation} src={props.img} alt={props.alt} />
        </div>
      </div>
    );
  }

  const listLogo = Logos;
  const listLogos = listLogo.map((listLogo) => (
    <ClientItem
      key={listLogo.toString()}
      orientation={listLogo.orientation}
      img={listLogo.img}
      alt={listLogo.company}
    />
  ));

  return (
    <div className="client__section">
      <h2>trusted by these companies:</h2>
      <Marquee speed="14">
        {listLogos}
        {listLogos}
      </Marquee>
    </div>
  );
}

export default Client;
