import { AnimatePresence } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavMain from "../components/NavMain";

import events from "../data/news.json";

function Post(props) {
  const data = props.match.params.id - 1;
  console.log(data);

  return (
    <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
      <NavMain />
      <div className="post__breadcrumbs">
        <Link to={"/newsandevents"}>&#60;&#60; Back</Link>
      </div>
      <div className="post__container">
        <div className="post__body">
          <div className="post__info">
            <p className="post__category">{events[data].category}</p>
            <p className="post__category">{events[data].date}</p>
          </div>
          <h1 className="post__title">{events[data].title}</h1>
          <img className="post__img" src={events[data].img} alt="" />
          <div
            dangerouslySetInnerHTML={{ __html: events[data].content }}
            className="post__content"
          ></div>
        </div>
      </div>
      <Footer />
    </AnimatePresence>
  );
}

export default Post;
