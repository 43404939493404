import React from "react";
import { Button } from "./Button";
import ProjectCard from "./ProjectCard";
import logo1 from "../images/logo/logo-09.png";
import logo2 from "../images/logo/logo-05.png";
import image1 from "../images/sigita.png";
import image2 from "../images/datacapture.png";

function Products() {
  return (
    <div className="product__section" id="projects">
      <div data-aos="fade-up" className="content__title">
        <h1 className>our projects</h1>
      </div>
      <div className="p__cards__container">
        <ProjectCard
          link="/projects/sigita"
          name="Sigita"
          type="Portal ArcGIS"
          color="blue"
          logo={logo1}
          img={image1}
        />
        <ProjectCard
          link="/projects/datacapture"
          name="Data Capture"
          type="Mobile App"
          color="orange"
          logo={logo2}
          img={image2}
        />
        <ProjectCard
          link="/projects/datacapture"
          name="Data Capture"
          type="Mobile App"
          color="purple"
          logo={logo2}
          img={image2}
        />
      </div>
      <div className="section__footer">
        <Button to="/404" className="btn-projects">
          all projects
        </Button>
      </div>
    </div>
  );
}

export default Products;
