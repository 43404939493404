import React from "react";
import { Link } from "react-router-dom";
import Tilt from "react-tilt";

function CardItem(props) {
  return (
    <>
      <Link data-aos="fade-up" to={props.linkto} className="card__item__link">
        <Tilt className="tilt-helper" options={{ max: 15, speed: 500 }}>
          <div className={`card__item__container ${props.color}`}>
            <div className="card__item__text">
              <h3>{props.solution}</h3>
              <h5>Learn More >>></h5>
            </div>
            <div className="card__image__container"></div>
            <img src={props.image} alt="" className="card__image" />
          </div>
        </Tilt>
      </Link>
    </>
  );
}

export default CardItem;
