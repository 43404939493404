import "./App.css";
// import Navbar, {
//   Dropdown,
//   DropdownDesktop,
//   NavItem,
//   NavMenu,
// } from "./components/Navbar";
// import Footer from "./components/Footer";
import {
  Switch,
  Route,
  useLocation,
  HashRouter as Router,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./styles/main.scss";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import Home from "./pages/Home";
import AWE from "./pages/AWE";
import SAM from "./pages/SAM";
import PHD from "./pages/PHD";
import IOT from "./pages/IOT";
import DataAcquisition from "./pages/DataAcq";
import ContactUs from "./pages/ContactUs";
import NewsAndEvents from "./pages/NewsAndEvents";
import UnderConstruction from "./pages/UnderConstruction";
import Post from "./pages/Post";
// import NavMain from "./components/NavMain";

// import ScrollToTop from "./components/ScrollToTop";

function App() {
  const location = useLocation();
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  return (
    <>
      {/* <Navbar>
        <NavMenu icon="Solutions +">
          <DropdownDesktop></DropdownDesktop>
        </NavMenu>
        <NavMenu to="/contactus" icon="Contact Us" />
        <NavMenu to="/404" icon="News & Events" />
        <a
          className="nav__item desktop login"
          href="https://services.mitratechindonesia.co.id/dashboard/"
          target="_blank"
          rel="noreferrer"
        >
          Login
        </a>
        <NavItem>
          <Dropdown></Dropdown>
        </NavItem>
      </Navbar> */}
      <AnimatePresence
        // initial={false}
        exitBeforeEnter
        // onExitComplete={console.log("exit")}
      >
        <Router basename={"/"}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/smartawe" exact component={AWE} />
            <Route path="/spatialasset" exact component={SAM} />
            <Route path="/phdmanagement" exact component={PHD} />
            <Route path="/iotintegration" exact component={IOT} />
            <Route path="/dataacquisition" exact component={DataAcquisition} />
            <Route path="/contactus" exact component={ContactUs} />
            <Route path="/newsandevents" exact component={NewsAndEvents} />
            <Route path="/newsandevents/:id" exact component={Post} />
            <Route component={UnderConstruction} />
          </Switch>
        </Router>
      </AnimatePresence>
    </>
  );
}

export default App;
