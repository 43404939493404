import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Footer from "../components/Footer";
import NavMain from "../components/NavMain";
import ProductBanner from "../components/ProductBanner";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import ProductProjects from "../components/ProductProjects";
import { Link } from "react-router-dom";

const banner = [
  {
    product: "PhD Management System",
    line1:
      "Scale up your time utilization by manage all operations administrative and projects as easy as “Playing Mobile Games”",
    line2: null,
    banner: `${require("../images/phd.jpg").default}`,
  },
];

const description =
  "This integrated system is a comprehensive solution to manage end-to-end cycle processes of submitting, storing, and reporting personnel & document administration to meet the business operational needs for faster, accurate, accountable, and realtime up-to-date of data synchronization.";

const features = [
  {
    img: `${require("../images/screenshot/C1.png").default}`,
    title:
      "Mobile absence application embedded with georeference and time module",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/C2.png").default}`,
    title:
      "Performance Management System with Realtime Task Management Monitoring",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/C3.png").default}`,
    title:
      "Customized workflow to manage task, tiered approval and complex document management",
    desc: "Customized workflow to manage task, tiered approval and complex document management",
  },
];

const benefits = [
  {
    img: `${require("../images/solution/PHD 1.png").default}`,
    title: "Increase the Productivity of Your Employees",
    text: "Lessen the time needed for your employees to do non-productive related works such as bureaucracy, attendancy, etc.",
  },
  {
    img: `${require("../images/solution/PHD 2.png").default}`,
    title:
      "Record Activites Comprehensively With Time and Location Perspective",
    text: "Know in and out of your employee activities in the office and in the field that can be analyzed to further improve company preformance",
  },
  {
    img: `${require("../images/solution/PHD 3.png").default}`,
    title: "Powerful and Comprehensive Report",
    text: "Transparancy and reliability of operation reports for easiness of decision making process",
  },
];

const projects = [
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "blue",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
  {
    link: "/projects/datacapture",
    name: "Data Capture",
    type: "Mobile App",
    color: "purple",
    logo: `${require("../images/logo/logo-05.png").default}`,
    img: `${require("../images/datacapture.png").default}`,
  },
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "red",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
];

function PHD() {
  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <NavMain />
          <ProductBanner data={banner} />
          <ProductFeatures data={features} desc={description} />
          <ProductBenefits data={benefits} />
          <ProductProjects data={projects} />
          <div className="news solution">
            <img src={require("../images/question.png").default} alt="" />
            <h2>Got any question?</h2>
            <Link to="contactus">Send us a message</Link>
          </div>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default PHD;
