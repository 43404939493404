import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavMain from "../components/NavMain";
import Pagination from "../components/Pagination";

import Fuse from "fuse.js";

import events from "../data/news.json";
import img from "../images/news.jpg";
import { AnimatePresence } from "framer-motion";

// const searchResult = result.map((result) => result.item);

// console.log(result);

function NewsAndEvents() {
  function NewsCard(props) {
    return (
      <Link
        props={props.props}
        to={"/newsandevents/" + props.id}
        className="news__card"
      >
        <img className="news__card__img" src={props.img} alt="" />
        <div className="news__card__content">
          <h3 className="news__card__title">{props.title}</h3>
          <div className="news__card__info">
            <h5 className="news__card__category">{props.category}</h5>
            <h5 className="news__card__date">{props.date}</h5>
          </div>
        </div>
      </Link>
    );
  }

  const [query, setQuery] = useState("");

  const fuse = new Fuse(events, {
    keys: ["title", "category", "date"],
    shouldSort: true,
    includeScore: true,
    threshold: 0.35,
    ignoreLocation: true,
  });

  const result = fuse.search(query);

  function handleOnSearch({ currentTarget = {} }) {
    const { value } = currentTarget;
    setQuery(value);
  }

  // const [posts, setPosts] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(6);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = query
    ? result.slice(indexOfFirstPost, indexOfLastPost)
    : events.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalResult = query ? result.length : events.length;

  const listEvent = currentPosts;
  // const listEvents = listEvent.map((listEvent) => (
  const searchResult =
    query && result.length === 0 ? (
      <h1 className="empty-search">Data tidak ditemukan</h1>
    ) : query && result.length !== 0 ? (
      currentPosts.map((result) => (
        <NewsCard
          key={result.item.toString()}
          title={result.item.title}
          category={result.item.category}
          date={result.item.date}
          img={result.item.img}
          id={result.item.id}
          props={result.item}
        />
      ))
    ) : (
      listEvent.map((listEvent) => (
        <NewsCard
          key={listEvent.toString()}
          title={listEvent.title}
          category={listEvent.category}
          date={listEvent.date}
          img={listEvent.img}
          id={listEvent.id}
          props={listEvent}
        />
      ))
    );
  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
        <NavMain />
        <div className="news__header">
          <h1>Our Latest News and Events</h1>
        </div>
        <div className="news__list">
          <form className="news__search">
            <input
              type="text"
              value={query}
              onChange={handleOnSearch}
              placeholder="Search News..."
            />{" "}
            {!query || totalResult !== 0 ? (
              <p>
                Showing {currentPage * [postPerPage] - ([postPerPage] - 1)}-
                {indexOfLastPost >= totalResult ? totalResult : indexOfLastPost}{" "}
                of {""}
                {totalResult} result
              </p>
            ) : (
              <p>0 result found</p>
            )}
          </form>
          <div className="news__card__container">
            {searchResult}
            <div className="empty-divs"></div>
            <div className="empty-divs"></div>
          </div>

          <Pagination
            postPerPage={postPerPage}
            totalPost={query ? result.length : events.length}
            paginate={paginate}
          />
        </div>
        <Footer />
      </AnimatePresence>
    </>
  );
}

export default NewsAndEvents;
