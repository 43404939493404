import React from "react";
import Navbar, { NavMenu, DropdownDesktop, NavItem, Dropdown } from "./Navbar";

function NavMain() {
  return (
    <>
      <Navbar>
        <NavMenu icon="Solutions +">
          <DropdownDesktop></DropdownDesktop>
        </NavMenu>
        <NavMenu to="/contactus" icon="Contact Us" />
        <NavMenu to="/newsandevents" icon="News & Events" />
        <a
          className="nav__item desktop login"
          href="https://services.mitratechindonesia.co.id/dashboard/"
          target="_blank"
          rel="noreferrer"
        >
          Login
        </a>
        <NavItem>
          <Dropdown></Dropdown>
        </NavItem>
      </Navbar>
    </>
  );
}

export default NavMain;
