import React from "react";
import { motion } from "framer-motion";

// import { Button } from "./Button";

function ProductBanner(props) {
  return (
    <>
      <div className="product-banner">
        <motion.div
          initial={{ x: "-10vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="banner__text"
        >
          <h1>{props.data[0].product}</h1>
          <p>
            {props.data[0].line1}
            <br />
            {props.data[0].line2}
          </p>
          <div className="banner__button__container">
            <a href="#features" className="btn btn--dark btn--wide">
              Features
            </a>
            <a href="#projects" className="btn btn--dark btn--wide">
              Our projects
            </a>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: "10vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="banner__image"
        >
          <img src={props.data[0].banner} alt="" />
        </motion.div>
      </div>
    </>
  );
}

export default ProductBanner;
