import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import ProductBanner from "../components/ProductBanner";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import ProductProjects from "../components/ProductProjects";
import NavMain from "../components/NavMain";
import Footer from "../components/Footer";

const banner = [
  {
    product: "Smart AWE",
    line1: "Get closer to your clients faster, without hassle.",
    line2:
      "Instantaneous response, personalized reply, custom-made for your needs.",
    banner: `${require("../images/awe.jpg").default}`,
  },
];

const description =
  "Automatic Whatsapp Engine is an interactive chatbot program or software that can be used speficially in the popular encrypted messaging application Whatsapp.";

const features = [
  {
    img: `${require("../images/screenshot/A1.png").default}`,
    title:
      "Fast and interactive response with customized options based on customer business processes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/A2.png").default}`,
    title:
      "Customized interactive chat-bot program to fit your operation process",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/A3.png").default}`,
    title:
      "One portal to manage all of your business communication with customers",
    desc: "Easiness of creating applications for registering, maintaining, visualizing and analyzing asset data",
  },
];

const benefits = [
  {
    img: `${require("../images/solution/AWE 1.png").default}`,
    title: "Increase Your Customer Satisfaction",
    text: "With near instantaneous, helpful, and detailed responses, Customer will have all the answer they need.",
  },
  {
    img: `${require("../images/solution/AWE 2.png").default}`,
    title: "Reduce Company’s Operational and Maintenance Cost",
    text: "Easy to maintain and low resources need make the system cost less than traditional man-operated system",
  },
  {
    img: `${require("../images/solution/AWE 3.png").default}`,
    title: "Level-up Your Company-Customer Communication",
    text: "Scale up to next level of provide service to the customer in the process of marketing activities and handling complaints",
  },
];

const projects = [
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "blue",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
  {
    link: "/projects/datacapture",
    name: "Data Capture",
    type: "Mobile App",
    color: "purple",
    logo: `${require("../images/logo/logo-05.png").default}`,
    img: `${require("../images/datacapture.png").default}`,
  },
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "red",
    logo: `${require("../images/logo/logo-09.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
];

function AWE() {
  return (
    <>
      <AnimatePresence
        // initial={false}
        exitBeforeEnter
        onExitComplete={window.scrollTo(0, 0)}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <NavMain />
          <ProductBanner data={banner} />
          <ProductFeatures data={features} desc={description} />
          <ProductBenefits data={benefits} />
          <ProductProjects data={projects} />
          <div className="news solution">
            <img src={require("../images/question.png").default} alt="" />
            <h2 data-aos="fade-up">Got any question?</h2>
            <Link data-aos="fade-up" to="/contactus">
              Send us a message
            </Link>
          </div>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default AWE;
