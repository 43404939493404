import React from "react";
import Footer from "../components/Footer";
import NavMain from "../components/NavMain";

function UnderConstruction() {
  return (
    <>
      <NavMain />
      <div className="under-construction">
        <h1>Under Construction</h1>
        <img src={require("../images/404.png").default} alt="" />
      </div>
      <Footer />
    </>
  );
}

export default UnderConstruction;
