import React from "react";
import ProjectCard from "./ProjectCard";
import { Button } from "./Button";

function ProductProjects(props) {
  return (
    <div className="product__section" id="projects">
      <div data-aos="fade-up" className="content__title">
        <h1>projects</h1>
      </div>
      <div className="p__cards__container">
        <ProjectCard
          link={props.data[0].link}
          name={props.data[0].name}
          type={props.data[0].type}
          color={props.data[0].color}
          logo={props.data[0].logo}
          img={props.data[0].img}
        />
        <ProjectCard
          link={props.data[1].link}
          name={props.data[1].name}
          type={props.data[1].type}
          color={props.data[1].color}
          logo={props.data[1].logo}
          img={props.data[1].img}
        />
        <ProjectCard
          link={props.data[2].link}
          name={props.data[2].name}
          type={props.data[2].type}
          color={props.data[2].color}
          logo={props.data[2].logo}
          img={props.data[2].img}
        />
      </div>
      <div className="section__footer">
        <Button to="/404" className="btn-projects">
          all projects
        </Button>
      </div>
    </div>
  );
}

export default ProductProjects;
