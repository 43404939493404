import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

import img1 from "../images/about-01.png";
import img2 from "../images/about-02.png";
import img3 from "../images/about-03.png";

SwiperCore.use([Autoplay, Pagination]);

function About() {
  return (
    <div className="section__about">
      <div data-aos="fade-up" className="content__title">
        <h1>about us</h1>
      </div>
      <div className="text">
        <p data-aos="fade-up">
          <span>Mitratech Indonesia</span> is a Leading Solution Provider &
          System Integrator that focuses on providing Comprehensive Solution to
          the Client.
        </p>
      </div>
      <Swiper
        data-aos="fade-up"
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        className="swiper__mobile"
      >
        <SwiperSlide className="about__slide__item">
          <div className="about__slide">
            <div className="about__slide__top">
              <img src={img1} alt="" />
              <h3>Customizable</h3>
            </div>
            <div className="about__slide__bot">
              <p>Every projects tailored to your company needs</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="about__slide">
            <div className="about__slide__top">
              <img src={img2} alt="" />
              <h3>Professional</h3>
            </div>
            <div className="about__slide__bot">
              <p>
                40+ employees specializing in System Integration Service with
                international qualification standards
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="about__slide">
            <div className="about__slide__top">
              <img src={img3} alt="" />
              <h3>Spatial Based</h3>
            </div>
            <div className="about__slide__bot">
              <p>
                All data have spatial data to better visualize your needs,
                strengths, and weaknesses
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="about__desktop">
        <div data-aos="fade-up" className="about__slide">
          <div className="about__slide__top">
            <img src={img1} alt="" />
            <h3>Customizable</h3>
          </div>
          <div className="about__slide__bot">
            <p>Every projects tailored to your company needs</p>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="100" className="about__slide">
          <div className="about__slide__top">
            <img src={img2} alt="" />
            <h3>Professional</h3>
          </div>
          <div className="about__slide__bot">
            <p>
              40+ employees specializing in System Integration Service with
              International qualification standards
            </p>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="200" className="about__slide">
          <div className="about__slide__top">
            <img src={img3} alt="" />
            <h3>Spatial Based</h3>
          </div>
          <div className="about__slide__bot">
            <p>
              All data have spatial data to better visualize your needs,
              strengths, and weaknesses
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
