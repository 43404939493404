import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

import NavMain from "../components/NavMain";
import ProductBanner from "../components/ProductBanner";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import ProductProjects from "../components/ProductProjects";

const banner = [
  {
    product: "IoT Integration",
    line1:
      "Achieving “Smart Operations 4.0” on your company by maximizing all of data sources capabilities.",
    line2: null,
    banner: `${require("../images/iot.jpg").default}`,
  },
];

const description =
  "Combining Artificial Intelligence, Machine Learning, & Deep Learning technology in the computer vision area.";

const features = [
  {
    img: `${require("../images/screenshot/D1.png").default}`,
    title: "Real time integration to any of in house/3rd party existing system",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/D2.png").default}`,
    title:
      "Data output can be visualized and analyzed on preferred end user’s front end application (Operation Dashboard, Web Application or Mobile Application)",
    desc: "Data output can be visualized and analyzed on preferred end user’s front end application (Operation Dashboard, Web Application or Mobile Application)",
  },
  {
    img: `${require("../images/screenshot/D3.png").default}`,
    title:
      "Predictive analytic module with Machine Learning, Artificial Intelligence (AI) and Deep Learning approachment",
    desc: "Predictive analytic module with Machine Learning, Artificial Intelligence (AI) and Deep Learning approachment",
  },
];

const benefits = [
  {
    img: `${require("../images/solution/IOT 1.png").default}`,
    title: "Faster response to resolve any incidental/condition occured ",
    text: "Shorten your incident’s response time with real-time monitoring of your point of interest priority. No more delayed feedback to make a better emergency handling",
  },
  {
    img: `${require("../images/solution/IOT 2.png").default}`,
    title: "Seamless integration to the other legacy system",
    text: "No proprietary system is needed, not only to minimize cost to implement all of our IoT solution but remove the need to adapt to the new system",
  },
  {
    img: `${require("../images/solution/IOT 3.png").default}`,
    title: "Cost efficiency to manage company’s business operation",
    text: "Automated and computerized operation means less human-error involved and less cost to operate the company",
  },
];

const projects = [
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "blue",
    logo: `${require("../images/logo/logo-06.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
  {
    link: "/projects/datacapture",
    name: "Data Capture",
    type: "Mobile App",
    color: "purple",
    logo: `${require("../images/logo/logo-01.png").default}`,
    img: `${require("../images/datacapture.png").default}`,
  },
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "red",
    logo: `${require("../images/logo/logo-06.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
];

function IOT() {
  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <NavMain />
          <ProductBanner data={banner} />
          <ProductFeatures data={features} desc={description} />
          <ProductBenefits data={benefits} />
          <ProductProjects data={projects} />
          <div className="news solution">
            <img src={require("../images/question.png").default} alt="" />
            <h2>Got any question?</h2>
            <Link to="contactus">Send us a message</Link>
          </div>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default IOT;
