import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="sitemap__main">
          <div className="sitemap__logo">
            <h2>
              Mitratech <span>Indonesia</span>
            </h2>
            <h5>Your trusted IT Solution Partner</h5>
          </div>
          <div className="sitemap__connect">
            <h4>Connect with us</h4>
            <div className="sitemap__socmed">
              <a
                href="https://www.instagram.com/mitratechindonesia/"
                target="blank"
              >
                <img src={require("../images/instagram.png").default} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/mitratechindonesia/"
                target="blank"
              >
                <img src={require("../images/linkedin.png").default} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="sitemap__links">
          <div className="sitemap__links__list">
            <h4>Solutions</h4>
            <ul>
              <li>
                <Link to="/smartawe">
                  <h5>Smart AWE</h5>
                </Link>
              </li>
              <li>
                <Link to="/spatialasset">
                  <h5>Spatial Asset Management</h5>
                </Link>
              </li>
              <li>
                <Link to="/phdmanagement">
                  <h5>PhD Management System</h5>
                </Link>
              </li>
              <li>
                <Link to="/iotintegration">
                  <h5>IoT Integration</h5>
                </Link>
              </li>
              <li>
                <Link to="/dataacquisition">
                  <h5>Data Acquisition</h5>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sitemap__links__list">
            <h4>Other Links</h4>
            <ul>
              <li>
                <Link to="/404">
                  <h5>Our Projects</h5>
                </Link>
              </li>
              <li>
                <Link to="/contactus">
                  <h5>Contact Us</h5>
                </Link>
              </li>
              <li>
                <Link to="/newsandevents">
                  <h5>News and Events</h5>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="acknowledgement">© PT. Mitra Tech Indonesia 2021</div>
    </>
  );
}

export default Footer;
