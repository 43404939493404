import React from "react";
import CardItem from "./CardItem";

const info = [
  {
    link: "/smartawe",
    solution: "Smart AWE",
    color: "red",
    image: `${require("../images/2.png").default}`,
  },
  {
    link: "/spatialasset",
    solution: "Spatial Asset Management",
    color: "blue",
    image: `${require("../images/1.png").default}`,
  },
  {
    link: "/phdmanagement",
    solution: "PhD Management System",
    color: "green",
    image: `${require("../images/3.png").default}`,
  },
  {
    link: "/iotintegration",
    solution: "IoT Integration",
    color: "purple",
    image: `${require("../images/4.png").default}`,
  },
  {
    link: "/dataacquisition",
    solution: "Data Acquisition",
    color: "orange",
    image: `${require("../images/5.png").default}`,
  },
];

function Cards() {
  return (
    <div className="cards" id="solutions">
      <div data-aos="fade-up" className="content__title">
        <h1>our solutions</h1>
      </div>
      <div className="cards__container">
        <CardItem
          linkto={info[0].link}
          solution={info[0].solution}
          color={info[0].color}
          image={info[0].image}
        />
        <CardItem
          linkto={info[1].link}
          solution={info[1].solution}
          color={info[1].color}
          image={info[1].image}
        />
        <CardItem
          linkto={info[2].link}
          solution={info[2].solution}
          color={info[2].color}
          image={info[2].image}
        />
        <CardItem
          linkto={info[3].link}
          solution={info[3].solution}
          color={info[3].color}
          image={info[3].image}
        />
        <CardItem
          linkto={info[4].link}
          solution={info[4].solution}
          color={info[4].color}
          image={info[4].image}
        />
      </div>
    </div>
  );
}

export default Cards;
