import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavMain from "../components/NavMain";
import ProductBanner from "../components/ProductBanner";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import ProductProjects from "../components/ProductProjects";

const banner = [
  {
    product: "Data Acquisition",
    line1:
      "Capture and get the transparency of your business asset data with reliable tools, comprehensive knowledge and experienced implementer of data survey.",
    line2: null,
    banner: `${require("../images/data.jpg").default}`,
  },
];

const description =
  "Vital monitoring tool to understand, correct and improve business processes.";

const features = [
  {
    img: `${require("../images/screenshot/E1.png").default}`,
    title:
      "Comprehensive data captured using Mobile Apps, Drone and Aerial Photography",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/E2.png").default}`,
    title: "Seamless integration of data result with legacy operation system ",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro cumque est reiciendis. Illo commodi laborum laboriosam hic, placeat nobis vel enim saepe fugiat facilis perspiciatis.",
  },
  {
    img: `${require("../images/screenshot/E3.png").default}`,
    title: "Embedded with spatial analysis to gain a powerful business insight",
    desc: "Easiness of creating applications for registering, maintaining, visualizing and analyzing asset data",
  },
];

const benefits = [
  {
    img: `${require("../images/solution/DATA 1.png").default}`,
    title: "Make a clear visibility and insight of your valuable asset",
    text: "Visualizing all your asset and pour it all into comprehensive, easy-to-analyze, all-in-one powerful map that can be further cuztomize to your delicate needs",
  },
  {
    img: `${require("../images/solution/DATA 2.png").default}`,
    title: "Completeness and End to End cycle of solution provided",
    text: "Hassle free, one-stop solution to your ever expanding business. Our experienced expert will handle all the required steps even if your company has zero infrastructure in data acquisition",
  },
  {
    img: `${require("../images/solution/DATA 3.png").default}`,
    title: "“Bull’s eye”of decision making by using good reference data",
    text: "Visualizing data to easy-to-digest form will not only to improve real world accuracy but also to help important business decision making in the future.",
  },
];

const projects = [
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "blue",
    logo: `${require("../images/logo/logo-06.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
  {
    link: "/projects/datacapture",
    name: "Data Capture",
    type: "Mobile App",
    color: "purple",
    logo: `${require("../images/logo/logo-01.png").default}`,
    img: `${require("../images/datacapture.png").default}`,
  },
  {
    link: "/projects/sigita",
    name: "Sigita",
    type: "Portal ArcGIS",
    color: "red",
    logo: `${require("../images/logo/logo-06.png").default}`,
    img: `${require("../images/sigita.png").default}`,
  },
];

function DataAcquisition() {
  return (
    <>
      <AnimatePresence exitBeforeEnter onExitComplete={window.scrollTo(0, 0)}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          <NavMain />
          <ProductBanner data={banner} />
          <ProductFeatures data={features} desc={description} />
          <ProductBenefits data={benefits} />
          <ProductProjects data={projects} />
          <div className="news solution">
            <img src={require("../images/question.png").default} alt="" />
            <h2>Got any question?</h2>
            <Link to="contactus">Send us a message</Link>
          </div>
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default DataAcquisition;
