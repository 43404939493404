import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import NavMain from "../components/NavMain";
import Footer from "../components/Footer";

function ContactUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  // Parallax
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Parallax end

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
      >
        <NavMain />
        <div className="contact__banner">
          <h1>Get in touch!</h1>
          <h3>Any question or remarks? Just write us a message!</h3>
        </div>
        <div className="flex-container">
          <div
            className="contact__card__parallax"
            style={{ transform: `translateY(${offsetY * 0.2}px)` }}
          ></div>
          <div className="contact__card__container">
            <div className="contact__card">
              <img
                src={require("../images/contact-phone.png").default}
                alt=""
              />
              <a className="phone" href="tel:+622127802618">
                021 - 278 026 18
              </a>
            </div>
            <div className="contact__card">
              <img
                src={require("../images/contact-email.png").default}
                alt=""
              />
              <Link to="#">admin@mitratechindonesia.co.id</Link>
            </div>
            <div className="contact__card">
              <img
                src={require("../images/contact-address.png").default}
                alt=""
              />
              <a
                href="https://www.google.com/maps/place/Tempo+Scan+Tower/@-6.2352717,106.8270978,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f3e7d29f09e3:0xf765b0eee72f8837!8m2!3d-6.235277!4d106.8292865"
                target="blank"
              >
                Graha Simatupang <br /> TB Simatupang Kav.38, Jakarta Selatan
                12540
              </a>
            </div>
          </div>
          <div className="form__container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>or, leave a message!</h2>
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                placeholder="John Doe"
                id="fullName"
                {...register("fullName", { required: "Full name required" })}
              />
              {errors.fullName && <p>{errors.fullName.message}</p>}
              <label htmlFor="email">Email</label>
              <input
                type="text"
                placeholder="email@gmail.com"
                {...register("email", { required: "Email required" })}
              />
              {errors.email && <p>{errors.email.message}</p>}
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                placeholder="Enter your question here..."
                // cols="30"
                rows="10"
                {...register("message")}
              ></textarea>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div className="guest__book_container"></div>
        <Footer />
      </motion.div>
    </>
  );
}

export default ContactUs;
