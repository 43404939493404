import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import TestimonialItem from "./TestimonialItem";

SwiperCore.use([Navigation, EffectFade]);

const testimonials = [
  {
    name: "Yoga P. Pulungan, S.T., M.Si",
    company: "Kepala UPT PJU Dinas Perhubungan Kota Depok",
    quote: `Solusi yang sudah diberikan dari hasil kerja sama Unit PJU DISHUB kota Depok dengan Mitratech Indonesia sangat mendukung dikarenakan hasil dari Road Map yang terutama. Hasilnya PJU banyak mengetahui titik-titik lampu yang sudah terpasang, daya listrik, dan panelnya.`,
    img: `${require("../images/testi2.png").default}`,
  },
];

function Testimonial() {
  // Parallax
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Parallax end

  // Testimonial Items Mapping
  const listTestimonial = testimonials;
  const listTestimonials = listTestimonial.map((listTestimonial) => (
    <SwiperSlide>
      <TestimonialItem
        key={listTestimonial.toString()}
        name={listTestimonial.name}
        img={listTestimonial.img}
        company={listTestimonial.company}
        quote={listTestimonial.quote}
      />
    </SwiperSlide>
  ));
  return (
    <div className="testimonial">
      <div data-aos="fade-up" className="content__title">
        <h1>what they say</h1>
      </div>
      <div
        style={{ transform: `translateY(${offsetY * 0.2}px)` }}
        className="testimonial__parallax"
      ></div>
      <Swiper
        data-aos="fade-up"
        slidesPerView={1.5}
        navigation
        className="testimonial__swiper__container"
        loop={false}
        centeredSlides={true}
        spaceBetween={0}
      >
        {listTestimonials}
      </Swiper>
    </div>
  );
}

export default Testimonial;
