import React from "react";
import { Link } from "react-router-dom";
import Tilt from "react-tilt";

function ProjectCard(props) {
  return (
    <>
      <Tilt className="p__card__tilt" options={{ max: 15, speed: 500 }}>
        <Link
          data-aos="fade-up"
          to={props.link}
          className={`p__card__item ${props.color}`}
        >
          <div className="p__card__title">
            <div className="p__card__logo">
              <img src={props.logo} alt="" />
            </div>
            <div className="p__card__text">
              <h5 className="project__name">{props.name}</h5>
              <h5 className="project__type">{props.type}</h5>
            </div>
          </div>
          <div className="p__card__image">
            <img src={props.img} alt="" />
          </div>
        </Link>
      </Tilt>
    </>
  );
}

export default ProjectCard;
