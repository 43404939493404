import React from "react";

function CardBenefits(props) {
  return (
    <>
      <div className="benefits__card">
        <div data-aos="fade-up" className="benefits__card__image">
          <img src={props.img} alt="" />
        </div>
        <div data-aos="fade-up" className="benefits__card__text">
          <h2>{props.title}</h2>
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
}

export default CardBenefits;
